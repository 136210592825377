import { Controller } from 'stimulus';
import $ from 'jquery';
export default class extends Controller {
    static targets = ['parentSelect', 'childSelect'];

    async initialize() {
        this.selectChangeUrl = document.location.origin + `/grade_and_adj_profile/get_study_data.json`;
        this.originalChildSelectContent = this.childSelectTarget.innerHTML;
        this.populateChildSelect(); 
    }

    connect() {    
        this.parentSelectTarget.addEventListener('change', this.handleParentSelectChange.bind(this));
        $('.select2-search-box').select2(); 
        $('.select2-search-box').on('select2:open', function() {
            // Use a timeout to ensure the focus is set correctly
            setTimeout(function() {
            var searchField = $('.select2-search__field');
            if (searchField.length > 0) {
                searchField[0].focus();
            }
            }, 0); 
        });      
    }

    async handleParentSelectChange() {
        this.populateChildSelect();
    }

    async populateChildSelect(initialize = false) {
        this.StudyId = this.parentSelectTarget.value;
        if (!this.StudyId) {
            this.childSelectTarget.innerHTML = this.originalChildSelectContent;
            return;
        }
        const response = await fetch(this.selectChangeUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ study_id: this.StudyId }),
            method: 'POST',
        });

        const data = await response.json();
        const selectBox = this.childSelectTarget;
        selectBox.innerHTML = ''; 
        const defaultOption = document.createElement('option');
        defaultOption.value = '';
        defaultOption.innerHTML = 'Select Email';
        selectBox.appendChild(defaultOption);
        // Loop through data and add email options
        data.emails.forEach((email) => {
            const option = document.createElement('option');
            option.value = email;
            option.innerHTML = email;
            selectBox.appendChild(option);
        });
        // Set selected option if specified (e.g., during initialization)
        if (initialize) {
            selectBox.value = data.selectedEmail;
        }
    }
}
