export default class FileProcessingModule {
  constructor({ notifications, visitDate }) {
    this.notifications = notifications;
    this.visitDate = visitDate;
  }
  handleGlobalFileUpload(gradingFormElem, event) {
    this.notifications.showLoader();
    const { file, eyeSection } = event.detail;
    const dropzone = event.detail.dropzone;
    if (file) {
      this.processReceivedFile(file, eyeSection, gradingFormElem, dropzone);
    } else {
      this.notifications.warning(
        "No file selected. Please select an image file to proceed."
      );
      this.notifications.hideLoader();
    }
  }

  processReceivedFile(file, eyeSection, form, dropzone) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result.split(",")[1];
      this.performOcr(base64Image, eyeSection, form, file, dropzone);
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      this.notifications.warning(
        "Unable to read the file. Please ensure it is a valid image file and try again."
      );
      this.notifications.hideLoader();
    };
    try {
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Unexpected error during file reading:", error);
      this.notifications.warning(
        "An unexpected error occurred while reading the file. Please ensure the file format is correct and try again."
      );
      this.notifications.hideLoader();
    }
  }

  performOcr(base64Image, eyeSection, form, file, dropzone) {
    const url = "https://dev-heyex-sdar-api.apps.eyesol.net/ocr/";
    const inputJSON = { pdf: base64Image };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputJSON),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Server returned an error: HTTP status ${response.status}`
          );
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data && data.data.patient_details) {
          const isDataUpdated = this.updateFormWithData(eyeSection, data);
          if (isDataUpdated) {
            this.notifications.success("File processed successfully!");
          } else {
            this.emitRemoveEvent(file, dropzone);
          }
        } else {
          throw new Error(
            "Unexpected response format from the server. Please contact support."
          );
        }
      })
      .catch((error) => {
        console.error("File processing error:", error);
        this.notifications.warning(
          `An error occurred while processing the file. ${
            error.message || "Please try again."
          }`
        );
      })
      .finally(() => {
        this.notifications.hideLoader();
      });
  }

  updateFormWithData(eyeSection, data) {
    if (!data || !data.data || !data.data.patient_details) {
      this.notifications.warning("Invalid data provided.");
      return false;
    }

    const { laterality, examDate } = data.data.patient_details;

    if (this.visitDate !== examDate) {
      const proceed = confirm(
        "The visit date in EyeSol and the exam date in the PDF report are different.Do you want to proceed in any case?"
      );
      if (!proceed) {
        this.notifications.warning(
          "Operation cancelled due to mismatched Visit dates."
        );
        return false;
      }
    }

    if (!laterality) {
      this.notifications.warning("Laterality information is missing.");
      return false;
    }

    if (eyeSection !== laterality && laterality !== "OU") {
      this.notifications.hideLoader();
      this.notifications.warning(
        "Please upload a PDF report from the correct eye."
      );
      return false;
    }

    const inputs = document.querySelectorAll("[data-screenshot]");
    inputs.forEach((component) => {
      const [key, side] = component.getAttribute("data-screenshot").split("-");
      let value = null;

      if (laterality === "OU") {
        // Update both sections if laterality is OU
        if (side === "OS" || side === "OD") {
          value =
            key in data.data[side]?.etdrs_volume
              ? data.data[side].etdrs_volume[key]
              : data.data[side]?.etdrs_thickness?.[key];
        }
      } else if (side === laterality) {
        // Update only the matching section for OD or OS
        value =
          key in data.data[laterality]?.etdrs_volume
            ? data.data[laterality].etdrs_volume[key]
            : data.data[laterality]?.etdrs_thickness?.[key];
      }

      if (value !== null && value !== "N/A") {
        component.value = value;
        component.setAttribute("value", value);
        const event = new Event("input", { bubbles: true });
        component.dispatchEvent(event);
      } else if (side === laterality || laterality === "OU") {
        component.setAttribute("value", " ");
        const event = new Event("input", { bubbles: true });
        component.dispatchEvent(event);
      }
    });

    this.notifications.hideLoader();
    return true;
  }

  // Method to initialize the Dropzone controller
  initializeDropzoneController() {
    this.dropzoneController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "dropzone"
      );

    if (!this.dropzoneController) {
      console.error("DropzoneController not found.");
    }
  }

  emitRemoveEvent(file, dropzone) {
    // Dispatch the custom event to notify other parts of the application
    const event = new CustomEvent("removeFile", {
      detail: {
        fileName: file.name,
      },
      bubbles: true,
    });
    window.dispatchEvent(event);
    // Remove only the specified file from Dropzone
    if (dropzone && file) {
      dropzone.removeFile(file); // This removes the specific file from Dropzone
    }
  }
}
